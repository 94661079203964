<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner :show="pageIsLoading" />

            <template v-if="!pageIsLoading">
                <PageHeading :breadcrumbs="breadcrumbs" title="Add category" />

                <CategoryCard
                    v-if="categoryDetails.category"
                    ref="categoryCard"
                    :category-details="categoryDetails"
                    :category-is-valid="categoryIsValid"
                    :already-in-use="colorsAlreadyInUse"
                    :slug-is-editable="true"
                    @confirmButtonClick="confirmButtonClick"
                />
                <Stepper
                    show-back-button
                    show-next-button
                    next-button-text="Save Category"
                    :is-loading="false"
                    @backButtonOnClick="backButtonClick($event)"
                    @nextButtonOnClick="confirmButtonClick($event)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import PageHeading from '@/components/ui/PageHeading';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import CategoryCard from '@/components/CategoryCard';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import Stepper from '@/components/ui/Stepper';
import { router } from '../router';

export default {
    name: 'CategoryCreate',
    components: {
        FullPageLoadingSpinner,
        PageHeading,
        CategoryCard,
        Stepper,
    },
    data() {
        return {
            LoadingFlag,
            colorsAlreadyInUse: [],
            breadcrumbs: [],
        };
    },
    computed: {
        ...mapState('category', ['categoryList']),
        ...mapGetters('category', {
            categories: 'categories',
            categoryDetails: 'categoryDetails',
            categoryModelHasChanges: 'categoryModelHasChanges',
            categoryIsValid: 'categoryIsValid',
            getColorsInUse: 'getColorsInUse',
        }),
        pageIsLoading() {
            return this.$wait.is(LoadingFlag.CategoryGet) && this.categoryList.length === 0;
        },
    },
    async mounted() {
        if (!this.categories.length) {
            await this.getCategories();
        }
        await this.setColors();
        this.setBreadcrumbs();
    },
    methods: {
        ...mapActions('category', {
            validateCategory: 'validateCategory',
            saveCategory: 'saveCategory',
            getCategories: 'getCategories',
            resetCategoryDetailsState: 'resetCategoryDetailsState',
        }),
        setBreadcrumbs() {
            const breadcrumbs = [];
            const categoryLink = { name: 'category' };
            breadcrumbs.push(new Breadcrumb('categories', categoryLink));
            breadcrumbs.push(new Breadcrumb('New Category'));
            this.breadcrumbs = breadcrumbs;
        },
        setAlreadyInUseColors() {
            const categories = this.categoryList || [];
            categories.map((category) => this.colorsAlreadyInUse.push(category.color));
        },
        setColors() {
            const inUse = this.getColorsInUse || [];
            this.colorsAlreadyInUse = inUse.filter(
                (item) => item != this.categoryDetails.category.color,
            );
        },
        async confirmButtonClick() {
            await this.validateCategory();
            if (!this.categoryIsValid) {
                this.$refs.categoryCard.animateErrorMessage();
                return;
            }
            if (!this.categoryModelHasChanges) {
                return;
            }
            await this.saveCategory();

            this.$nextTick(() => {
                this.$router.push('category');
            });
        },
        backButtonClick() {
            router.push({ name: 'category' });
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (!this.$route.meta.group || to.meta.group !== this.$route.meta.group) {
            this.resetCategoryDetailsState();
        }

        next();
    },
};
</script>
